<template>
  <div class="wrapper resource-container">
    <div class="resource-header">
      <div class="resource-titile text-overflow-ellipsis-2">
        {{ title }}
      </div>
      <div class="resource-desc text-overflow-ellipsis-2">
        {{ description }}
      </div>
    </div>
    <div :class="{'expanse-content': resourceType === 'single'}">
      <ResourceWraper
        :show-more="showMore"
        :data-list="showMore ? dataList.slice(0, showMoreNum) : dataList" :resource-type="resourceType"
        @download="handleDownload" @play="handlePlay"
      />
    </div>
    <div v-if="isShowMore" class="expanse-container" :style="{'height': expanseHeight}">
      <div ref="expanseContainer" :class="{'expanse-content': resourceType === 'single'}">
        <ResourceWraper
          :show-more="showMore"
          :data-list="dataList.slice(showMoreNum)" :resource-type="resourceType"
          @download="handleDownload" @play="handlePlay"
        />
      </div>
    </div>
    <div v-if="isShowMore" class="expanse-bottom">
      <div class="bottom-more" @click="handleExpanse">
        <div class="iconfont icon-double-arrow-top" :class="{'icon-reverse': !isExpanse}" />
        <span class="expanse-text">{{ isExpanse ? langContext.retract : langContext.more }}</span>
      </div>
    </div>
    <VideoPopup ref="videoPopup" />
  </div>
</template>

<script>
import mixin from './mixin'
import trackReportMixin from '../Common/CommonMixin/trackReport'
import bus from 'common/bus'
import { s3Download } from 'common/utils.js';
import ResourceWraper from './ResourceWraper/web'
import VideoPopup from 'components/common/VideoPopup/index';

export default {
  components: {
    ResourceWraper,
    VideoPopup,
  },
  mixins: [mixin, trackReportMixin],
  data() {
    return {
      showMoreNum: 8, // 默认查看更多数量
    }
  },
  methods: {
    // 展示所有筛选项
    handleExpanse() {
      const marginHeight = this.resourceType === 'double' ? 40 : 0
      this.expanseHeight = this.isExpanse ? 0 : `${this.$refs.expanseContainer.offsetHeight + marginHeight}px`
      this.isExpanse = !this.isExpanse
    },
    async handleDownload({ id, name }) {
      this.buttonTrackReport({ buttonName: name })
      const materialsClickNum = window.localStorage.getItem('materialsClickNum') || 0
      window.localStorage.setItem('materialsClickNum', Number(materialsClickNum) + 1)

      if (!this.isLogin && Number(materialsClickNum) > this.allowMaterialsNum) {
        bus.$emit('login', { triggerSource: 'down' });
        return
      }
      // if (this.isLogin) {
      //   const userStatus = await this.checkUserStatus()
      //   if (!userStatus) return
      // }
      const resData = await this.getResourceData(id)
      if (!resData) return
      s3Download(resData.signedUrl)
      // gtm 资料组件下载埋点
      if (this.$store.state.locale === 'us') {
        window.dataLayer.push({
          event: 'download_resources_gtm',
        });
      }
    },
    async handlePlay({ id, name }) {
      this.buttonTrackReport({ buttonName: name })
      const materialsClickNum = window.localStorage.getItem('materialsClickNum') || 0
      window.localStorage.setItem('materialsClickNum', Number(materialsClickNum) + 1)

      if (!this.isLogin && Number(materialsClickNum) >= this.allowMaterialsNum) {
        bus.$emit('login');
        return
      }
      // if (this.isLogin) {
      //   const userStatus = await this.checkUserStatus()
      //   if (!userStatus) return
      // }
      const resData = await this.getResourceData(id)
      if (!resData) return
      this.$refs.videoPopup.handleOpen(resData.signedUrl)
    },
  },
}
</script>

<style lang="scss" scoped>
.resource-container {
  padding: 40px 40px 32px;
  border-radius: 16px;
  border: 1px solid #ebebeb;
  background: #fff url('./assets/imgs/web_bg.png') no-repeat;
  background-size: 142px 199px;
  background-position: 100% 0%;

  .resource-header {
    color: var(--txt-color-link);
    padding-right: 214px;

    .resource-titile {
      margin-bottom: 10px;
      font-size: 24px;
      font-weight: bold;
      line-height: 32px;
    }

    .resource-desc {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .expanse-content {
    /deep/.resource-wraper-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .resource-content {
        width: calc((100% - 20px) / 2);
      }
    }
  }

  .expanse-container {
    height: 0;
    overflow: hidden;
    transition: height 0.4s;
  }

  .expanse-bottom {
    display: flex;
    justify-content: center;
    padding-top: 30px;

    .bottom-more {
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.6);
      cursor: pointer;

      .icon-double-arrow-top {
        padding-top: 3px;
        font-size: 14px;
        transform: scale(0.7);
      }

      .icon-reverse {
        transform: rotate(180deg) scale(0.7);
      }

      .expanse-text {
        margin-left: 10px;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
      }

      &:hover {
        color: var(--txt-color-link);
      }
    }
  }
}
</style>
